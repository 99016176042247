import React, { useState, useEffect, useLayoutEffect } from 'react';
import { graphql, StaticQuery } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import useLocalStorage from '../hooks/useLocalStorage';

import '../utils/normalize.css';
import '../utils/css/screen.css';
import 'react-slideshow-image/dist/styles.css';

const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        phoneNumber
      }
    }
  }
`;

const Gallery = ({ data }, location) => {
  const siteTitle = data.site.siteMetadata.title;

  const galleryRef = React.useRef(null);

  const [tag, setTag] = useState('');
  const [autoScroll, setAutoScroll] = useState(false);
  const [limit, setLimit] = useState(null);
  const [galleryList, setGalleryList] = useState([]);
  const [filteredGalleryList, setFilteredGalleryList] = useState([]);

  const [galleryBlacklist, setGalleryBlacklist] = useLocalStorage('galleryBlacklist', []);

  const fetchGallery = (galleryTag) => {
    console.log('fetchGallery:', galleryTag);
    const options = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ tag: galleryTag, limit }),
    };
    fetch('https://us-central1-arteater.cloudfunctions.net/tag-gallery', options)
      .then(response => response.json())
      .then((jsonData) => {
        console.log('Fetched gallery data:');
        console.log(jsonData);
        setGalleryList(jsonData.galleryList);
      });
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log('params:', params.keys(), params.values());
    if (params.has('tag')) setTag(params.get('tag'));
    if (params.has('limit')) setLimit(params.get('limit'));
    if (params.has('auto-scroll')) setAutoScroll(params.get('auto-scroll') === 'true');

    console.log(galleryBlacklist);
  }, []);

  useLayoutEffect(() => {
    if (autoScroll) {
      setTimeout(() => {
        galleryRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
        });
      }, 1000);
    }
  }, [galleryList]);

  useEffect(() => {
    const filtered = galleryList.filter(item => !galleryBlacklist.includes(item));
    setFilteredGalleryList(filtered);
  }, [galleryList, galleryBlacklist]);

  useEffect(() => {
    fetchGallery(tag);

    const interval = setInterval(() => {
      console.log('rerun fetch effect', tag);
      fetchGallery(tag);
    }, 10000);

    // Clean up the interval when effect re-runs
    return () => clearInterval(interval);
  }, [tag]);

  return (
    <div style={{ backgroundColor: '#000' }}>
      <Layout title={siteTitle} minimalMode>
        <SEO
          title="Animation. For everyone."
          keywords={['animation', 'gallery', 'sketchbooks', 'gifs', 'paper', 'hand drawn']}
        />
        {data.site.siteMetadata.description && (
        <header className="page-head">
          <h2 className="page-head-title" style={{ color: '#CCC' }}>
            Gallery -
            {' '}
            {tag}
          </h2>
          <button
            type="button"
            onClick={() => setAutoScroll(!autoScroll)}
            style={{
              fontSize: '1rem',
              boxShadow: 'none',
            }}
          >
            {autoScroll ? 'Auto-scroll: ON' : 'Auto-scroll: OFF'}
          </button>
          {galleryList.length === 0
        && (
        <h5>
          Loading...
        </h5>
        )
      }
        </header>
        )}
        <div className="tag-gallery" ref={galleryRef}>
          {filteredGalleryList.map(gifUrl => (
            <div className="gallery-obj">
              <button onClick={() => setGalleryBlacklist([...galleryBlacklist, gifUrl])} className="close" type="button">
                <span>&times;</span>
              </button>
              <img
                key={`gif-${Math.random() * 100}`}
                src={gifUrl}
                alt="gif"
              />
            </div>
          ))}
        </div>
      </Layout>
    </div>
  );
};

const Checkbox = ({
  fnClick, fnChange, title = '', checked = false,
}) => (
  <label>
    <input
      onClick={(e) => {
        if (fnClick !== undefined) fnClick(e.target.checked);
      }}
      onChange={(e) => {
        if (fnChange !== undefined) fnChange(e.target.checked);
      }}
      type="checkbox"
      checked={checked}
    />
    {` Checkbox ${title}`}
  </label>
);

export default props => (
  <StaticQuery
    query={pageQuery}
    render={data => (
      <Gallery location={props.location} props data={data} {...props} />
    )}
  />
);
